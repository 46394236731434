// Generated by Framer (04e0834)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yz5GS81A8", "SVbxlhtrm", "cDS9_8JVf"];

const serializationHash = "framer-Kczmu"

const variantClassNames = {cDS9_8JVf: "framer-v-ylp5uy", SVbxlhtrm: "framer-v-9m2b6b", yz5GS81A8: "framer-v-ou561b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 1, ease: [0.44, 0, 0.56, 1], type: "tween"}

const transition2 = {delay: 0, duration: 0.6, ease: [0.44, 0, 0.56, 1], type: "tween"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"State 1": "yz5GS81A8", "State 2": "SVbxlhtrm", "State 3": "cDS9_8JVf"}

const getProps = ({height, id, indicatorColor, width, ...props}) => { return {...props, QbOKTnai0: indicatorColor ?? props.QbOKTnai0 ?? "rgb(27, 198, 81)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yz5GS81A8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;indicatorColor?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, QbOKTnai0, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yz5GS81A8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear19xl0t1 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("SVbxlhtrm"), 500)
})

const onAppear17da3md = activeVariantCallback(async (...args) => {
await delay(() => setVariant("cDS9_8JVf"), 750)
})

const onAppear14mkphb = activeVariantCallback(async (...args) => {
await delay(() => setVariant("yz5GS81A8"), 500)
})

useOnVariantChange(baseVariant, {cDS9_8JVf: onAppear14mkphb, default: onAppear19xl0t1, SVbxlhtrm: onAppear17da3md})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({cDS9_8JVf: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ou561b", className, classNames)} data-framer-name={"State 1"} data-highlight layoutDependency={layoutDependency} layoutId={"yz5GS81A8"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({cDS9_8JVf: {"data-framer-name": "State 3"}, SVbxlhtrm: {"data-framer-name": "State 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-phkxm4"} data-framer-name={"Indicator Pulse"} layoutDependency={layoutDependency} layoutId={"RuE5xWn6b"} style={{backgroundColor: QbOKTnai0, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", opacity: 1}} variants={{cDS9_8JVf: {opacity: 0}, SVbxlhtrm: {opacity: 0}}} {...addPropertyOverrides({cDS9_8JVf: {transformTemplate: transformTemplate1}, SVbxlhtrm: {transformTemplate: transformTemplate1}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1fjuuln"} data-framer-name={"Outer Circle"} layoutDependency={layoutDependency} layoutId={"Ra9Ric3WL"} style={{backgroundColor: QbOKTnai0, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/><motion.div className={"framer-1xsn5to"} data-framer-name={"Main Circle"} layoutDependency={layoutDependency} layoutId={"ORDtXvb2h"} style={{borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Kczmu.framer-onvfnw, .framer-Kczmu .framer-onvfnw { display: block; }", ".framer-Kczmu.framer-ou561b { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-Kczmu .framer-phkxm4, .framer-Kczmu .framer-1fjuuln { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-Kczmu .framer-1xsn5to { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 65%; justify-content: center; left: calc(50.00000000000002% - 65% / 2); padding: 0px; position: absolute; top: calc(50.00000000000002% - 65% / 2); width: 65%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Kczmu .framer-1xsn5to { gap: 0px; } .framer-Kczmu .framer-1xsn5to > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Kczmu .framer-1xsn5to > :first-child { margin-left: 0px; } .framer-Kczmu .framer-1xsn5to > :last-child { margin-right: 0px; } }", ".framer-Kczmu.framer-v-9m2b6b .framer-phkxm4 { height: var(--framer-aspect-ratio-supported, 40px); left: 50%; right: unset; top: 50%; width: 40px; }", ".framer-Kczmu.framer-v-ylp5uy .framer-phkxm4 { height: var(--framer-aspect-ratio-supported, 5px); left: 50%; right: unset; top: 50%; width: 5px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SVbxlhtrm":{"layout":["fixed","fixed"]},"cDS9_8JVf":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"QbOKTnai0":"indicatorColor"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWdEbo2ZHT: React.ComponentType<Props> = withCSS(Component, css, "framer-Kczmu") as typeof Component;
export default FramerWdEbo2ZHT;

FramerWdEbo2ZHT.displayName = "Author / Tooltip-Indicator";

FramerWdEbo2ZHT.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerWdEbo2ZHT, {variant: {options: ["yz5GS81A8", "SVbxlhtrm", "cDS9_8JVf"], optionTitles: ["State 1", "State 2", "State 3"], title: "Variant", type: ControlType.Enum}, QbOKTnai0: {defaultValue: "rgb(27, 198, 81)", title: "Indicator Color", type: ControlType.Color}} as any)

addFonts(FramerWdEbo2ZHT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})